exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-for-sale-js": () => import("./../../../src/pages/for-sale.js" /* webpackChunkName: "component---src-pages-for-sale-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parents-js": () => import("./../../../src/pages/parents.js" /* webpackChunkName: "component---src-pages-parents-js" */),
  "component---src-pages-puppies-then-and-now-js": () => import("./../../../src/pages/puppies-then-and-now.js" /* webpackChunkName: "component---src-pages-puppies-then-and-now-js" */),
  "component---src-pages-sold-js": () => import("./../../../src/pages/sold.js" /* webpackChunkName: "component---src-pages-sold-js" */)
}

